export default function Foot() {
    return (
        <>
            {/* JavaScript Libraries */}
            <script src="https://code.jquery.com/jquery-3.4.1.min.js"></script>
            <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0/dist/js/bootstrap.bundle.min.js"></script>
            <script src="/lib/wow/wow.min.js"></script>
            <script src="/lib/easing/easing.min.js"></script>
            <script src="/lib/waypoints/waypoints.min.js"></script>
            <script src="/lib/owlcarousel/owl.carousel.min.js"></script>

            {/* Template Javascript */}

            <script src="/js/main.js"></script>
        </>

    );
}
