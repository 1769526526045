import React,{ useState } from "react";
import axios from 'axios';

export default function Contact(props) {

    const initialValues = {
        fname: "",
        contact_email: "",
        subject: "",
        message: "",
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const [formData, setFormData] = useState(initialValues);

    function handleInputChange(e){
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    }

    async function handleSubmit(e) {

       e.preventDefault();
       const api = "http://127.0.0.1:8000/api/contact-data";

        try {
            const response = await axios.post(api, formData);
            console.log('Response:', response.data);
            setSuccess(true);
        } catch (err) {
            console.error('Error:', err);
            setError('There was an error submitting the form.');
        } finally {
            setLoading(false);
            // Reset form data after submission
            setFormData({
                fname: "",
                contact_email: "",
                subject: "",
                message: ""
            });

            alert("Your Enquiry Submitted Successfully");
        }
    }

    return (
            <div className="container-xxl py-5">
                <div className="container">
                    <div
                        className="text-center mx-auto mb-5 wow fadeInUp"
                        data-wow-delay="0.1s"
                        style={{ maxWidth: 600 }}
                    >
                        <h1 className="mb-3">Get In Touch</h1>
                        <p>
                            Eirmod sed ipsum dolor sit rebum labore magna erat. Tempor ut dolore
                            lorem kasd vero ipsum sit eirmod sit. Ipsum diam justo sed rebum vero
                            dolor duo.
                        </p>
                    </div>
                    <div className="row g-4 mb-5">
                        <div
                            className="col-md-6 col-lg-4 text-center wow fadeInUp"
                            data-wow-delay="0.1s"
                        >
                            <div
                                className="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4"
                                style={{ width: 75, height: 75 }}
                            >
                                <i className="fa fa-map-marker-alt fa-2x text-primary" />
                            </div>
                            <h6>123 Street, New York, USA</h6>
                        </div>
                        <div
                            className="col-md-6 col-lg-4 text-center wow fadeInUp"
                            data-wow-delay="0.3s"
                        >
                            <div
                                className="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4"
                                style={{ width: 75, height: 75 }}
                            >
                                <i className="fa fa-envelope-open fa-2x text-primary" />
                            </div>
                            <h6>php.laravel@hotmail.com</h6>
                        </div>
                        <div
                            className="col-md-6 col-lg-4 text-center wow fadeInUp"
                            data-wow-delay="0.5s"
                        >
                            <div
                                className="bg-light rounded-circle d-inline-flex align-items-center justify-content-center mb-4"
                                style={{ width: 75, height: 75 }}
                            >
                                <i className="fa fa-phone-alt fa-2x text-primary" />
                            </div>
                            <h6>{ props.mobile }</h6>
                        </div>
                    </div>
                    <div className="bg-light rounded">
                        <div className="row g-0">
                            <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                                <div className="h-100 d-flex flex-column justify-content-center p-5">
                                    <p className="mb-4">
                                        The contact form is currently inactive. Get a functional and
                                        working contact form with Ajax &amp; PHP in a few minutes. Just
                                        copy and paste the files, add a little code and you're done.{" "}
                                        <a href="https://htmlcodex.com/contact-form">Download Now</a>.
                                    </p>
                                    <form onSubmit={handleSubmit}>
                                        <div className="row g-3">
                                            <div className="col-sm-6">
                                                <div className="form-floating">
                                                    <input
                                                        type="text"
                                                        className="form-control border-0"
                                                        id="fname"
                                                        name="fname"
                                                        value={formData.fname}
                                                        onChange={handleInputChange}
                                                        placeholder="Your Name"
                                                        required
                                                    />
                                                    <label htmlFor="name">Your Name</label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-floating">
                                                    <input
                                                        type="email"
                                                        className="form-control border-0"
                                                        id="contact_email"
                                                        name="contact_email"
                                                        value={formData.contact_email}
                                                        required
                                                        onChange={handleInputChange}
                                                        placeholder="Your Email"
                                                    />
                                                    <label htmlFor="email">Your Email</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating">
                                                    <input
                                                        type="text"
                                                        className="form-control border-0"
                                                        id="subject"
                                                        name="subject"
                                                        value={formData.subject}
                                                        onChange={handleInputChange}
                                                        placeholder="Subject"
                                                        required
                                                    />
                                                    <label htmlFor="subject">Subject</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <div className="form-floating">
                      <textarea
                          className="form-control border-0"
                          placeholder="Leave a message here"
                          id="message"
                          name="message"
                          value={formData.message}
                          onChange={handleInputChange}
                          style={{ height: 100 }}
                          required
                          // defaultValue={""}
                      />
                                                    <label htmlFor="message">Message</label>
                                                </div>
                                            </div>
                                            <div className="col-12">
                                                <button
                                                    className="btn btn-primary w-100 py-3"
                                                    type="submit"
                                                    name="submit"
                                                     >
                                                    Send Message
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div
                                className="col-lg-6 wow fadeIn"
                                data-wow-delay="0.5s"
                                style={{ minHeight: 400 }}
                            >
                                <div className="position-relative h-100">
                                    <iframe
                                        className="position-relative rounded w-100 h-100"
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                                        frameBorder={0}
                                        style={{ minHeight: 400, border: 0 }}
                                        allowFullScreen=""
                                        aria-hidden="false"
                                        tabIndex={0}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}
