import Spinner from '../spinner/spinner'
import Carousel from '../carousel/carousel'
import About from '../about/about'
import Action from '../action/action'
import Facility from '../facilities/facilities'
import Classes from '../classes/classes'
import Appointment from '../appointment/appoint'
import Team from '../team/team'
import Testimonial from '../testimonial/testimonial'
import NavBar from '../navbar/nav'
import Footer from '../footer/footer'
import Foot from '../foot/foot'
import { useLocation } from 'react-router-dom'
import { Outlet } from 'react-router-dom';

const Home = () => {

    const location = useLocation();
    const isHomePage = location.pathname === '/';

    if(isHomePage) {
        return (
            <>
            <NavBar/>
            {/*<Spinner/>;*/}
            <Carousel />
            <Facility/>
            <About/>
            <Action/>
            <Classes/>
            <Appointment/>
            <Team/>
            <Testimonial/>
            <Footer/>
            <Foot/>
            </>
        );
    }
    else {
        return (
            <>
                <NavBar/>
                <Outlet/>
                <Footer/>
                <Foot/>
            </>
        );
    }
}

export default Home;
