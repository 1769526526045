import React,{ useState } from "react";
import { NavLink,Navigate,useLocation,useNavigate } from "react-router-dom";
import axios from "axios";
import Dashboard from "../dashboard/dashboard"


export default function Login() {

    const location = useLocation();
    const navigate = useNavigate();
    const initialValues = {
        login_email: "",
        login_password: "",
    };

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);

    const [formData, setFormData] = useState(initialValues);

    function handleInputChange(e){
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });

    }

   async function handleSubmit(e) {

           e.preventDefault();
           const api = "http://127.0.0.1:8000/api/check-login";
           setLoading(true);
           setSuccess(false);

       try {
               const response = await axios.post(api, formData);
               console.log('Response:', response);

               if (response.data.message !== "") {
                   setSuccess(true);
                   navigate("/dashboard")
               }

           } catch (err) {
               console.error('Error:', err);
               setError('There was an error Login.');
           } finally {
               setLoading(false);
               // Reset form data after submission
               setFormData({
                   login_email: "",
                   login_password: "",
               });

           }
    }

    return (
        <>
            <link href="/css/login.module.css" rel="stylesheet" />
            <div className="signup">
            <div className="signup-connect">
                <h1 align="center">Social Login</h1>
                <a href="https://www.facebook.com/login/" target="_blank" className="btn btn-social btn-facebook">
                    <i className="fa fa-facebook" /> Sign in with Facebook
                </a>
                <a href="#" className="btn btn-social btn-twitter">
                    <i className="fa fa-twitter" /> Sign in with Twitter
                </a>
                <a href="#" className="btn btn-social btn-google">
                    <i className="fa fa-google" /> Sign in with Google
                </a>
                <a href="#" className="btn btn-social btn-linkedin">
                    <i className="fa fa-linkedin" /> Sign in with Linkedin
                </a>
            </div>
            <div className="signup-classic">
                <NavLink to="/" className="badge btn-danger text-center">Home</NavLink>
                <h1 align="center">Login</h1>
                <form className="form" onSubmit={ handleSubmit }>
                    {/*<fieldset className="username">*/}
                        {/*<input type="text" placeholder="username" />*/}
                    {/*</fieldset>*/}
                    <fieldset className="email">
                        <input type="email" name="login_email" required onChange={ handleInputChange } id="login_email" placeholder="email" />
                    </fieldset>
                    <fieldset className="password">
                        <input type="password" name="login_password" required onChange={ handleInputChange } id="login_password" placeholder="password" />
                    </fieldset>
                    <button type="submit" className="btn">
                        login
                    </button>

                </form>

            </div>

                <div className="signup-connect">
                    <h1 align="center">Sign Up</h1>
                    <form className="form">
                        <fieldset className="username">
                        <input type="text" placeholder="username" />
                        </fieldset>
                        <fieldset className="email">
                            <input type="email" placeholder="email" />
                        </fieldset>
                        <fieldset className="password">
                            <input type="password" placeholder="password" />
                        </fieldset>
                        <button type="submit" className="btn">
                            sign up
                        </button>

                    </form>
                </div>


        </div>
            </>

    )
}