import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter,Routes,Route } from 'react-router-dom'
import Contact from './contact-us/contact'
import Classes from './classes/classes'
import About from './about/about'
import Home from './home/home'
import Team from './team/team'
import Testimonial from './testimonial/testimonial'
import Facility from './facilities/facilities'
import Action from './action/action'
import Appointment from './appointment/appoint'
import Login from './login/login'
import Dashboard from "./dashboard/dashboard"
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

let myname = "Bhupinder Singh";
let mypic = "img/bhupi.jpg";
let mobile = "+91 - 98769-31373";
let email = "php.laravel@hotmail.com";

root.render(
  <React.StrictMode>
      <BrowserRouter>
          <Routes>
              <Route path="/" element={ <Home /> }>
                  <Route path="/contact-us" element={ <Contact name={myname} picture={ mypic } mobile={ mobile } /> } />
                  <Route path="/about" element={ <About name={myname} picture={ mypic } mobile={ mobile } /> } />
                  <Route path="/classes" element={ <Classes /> } />
                  <Route path="/team" element={ <Team />  } />
                  <Route path="/testimonial" element={ <Testimonial />  } />
                  <Route path="/facility" element={ <Facility /> } />
                  <Route path="/action" element={ <Action /> } />
                  <Route path="/appointment" element={ <Appointment /> } />
              </Route>
          </Routes>

          <Routes>
              <Route path="/login"  element={ <Login /> } />
              <Route path="/dashboard" element={ <Dashboard /> } />
          </Routes>

          </BrowserRouter>

    </React.StrictMode>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
